// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aiemmat-luentomateriaalit-js": () => import("./../../../src/pages/aiemmat-luentomateriaalit.js" /* webpackChunkName: "component---src-pages-aiemmat-luentomateriaalit-js" */),
  "component---src-pages-ajankohtaista-js": () => import("./../../../src/pages/ajankohtaista.js" /* webpackChunkName: "component---src-pages-ajankohtaista-js" */),
  "component---src-pages-arkkujen-ja-uurnien-laatujarjestelma-js": () => import("./../../../src/pages/arkkujen-ja-uurnien-laatujarjestelma.js" /* webpackChunkName: "component---src-pages-arkkujen-ja-uurnien-laatujarjestelma-js" */),
  "component---src-pages-hautajaisjarjestelyni-js": () => import("./../../../src/pages/hautajaisjarjestelyni.js" /* webpackChunkName: "component---src-pages-hautajaisjarjestelyni-js" */),
  "component---src-pages-hautausmaa-alan-nimikkeisto-js": () => import("./../../../src/pages/hautausmaa-alan-nimikkeisto.js" /* webpackChunkName: "component---src-pages-hautausmaa-alan-nimikkeisto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jaseneksi-hakeutuminen-js": () => import("./../../../src/pages/jaseneksi-hakeutuminen.js" /* webpackChunkName: "component---src-pages-jaseneksi-hakeutuminen-js" */),
  "component---src-pages-kiitos-js": () => import("./../../../src/pages/kiitos.js" /* webpackChunkName: "component---src-pages-kiitos-js" */),
  "component---src-pages-laadun-tunnistamisprosessi-js": () => import("./../../../src/pages/laadun-tunnistamisprosessi.js" /* webpackChunkName: "component---src-pages-laadun-tunnistamisprosessi-js" */),
  "component---src-pages-liittohallitus-js": () => import("./../../../src/pages/liittohallitus.js" /* webpackChunkName: "component---src-pages-liittohallitus-js" */),
  "component---src-pages-linkit-js": () => import("./../../../src/pages/linkit.js" /* webpackChunkName: "component---src-pages-linkit-js" */),
  "component---src-pages-saadokset-js": () => import("./../../../src/pages/saadokset.js" /* webpackChunkName: "component---src-pages-saadokset-js" */),
  "component---src-pages-saannot-js": () => import("./../../../src/pages/saannot.js" /* webpackChunkName: "component---src-pages-saannot-js" */),
  "component---src-pages-sahkoinen-ilmoittautuminen-koulutuspaiville-js": () => import("./../../../src/pages/sahkoinen-ilmoittautuminen-koulutuspaiville.js" /* webpackChunkName: "component---src-pages-sahkoinen-ilmoittautuminen-koulutuspaiville-js" */),
  "component---src-pages-tietosuojaseloste-js": () => import("./../../../src/pages/tietosuojaseloste.js" /* webpackChunkName: "component---src-pages-tietosuojaseloste-js" */),
  "component---src-pages-tilastot-js": () => import("./../../../src/pages/tilastot.js" /* webpackChunkName: "component---src-pages-tilastot-js" */),
  "component---src-pages-toimintakertomukset-ja-poytakirjat-js": () => import("./../../../src/pages/toimintakertomukset-ja-poytakirjat.js" /* webpackChunkName: "component---src-pages-toimintakertomukset-ja-poytakirjat-js" */),
  "component---src-pages-yhteystiedot-js": () => import("./../../../src/pages/yhteystiedot.js" /* webpackChunkName: "component---src-pages-yhteystiedot-js" */)
}

